import { Component, OnInit } from '@angular/core';
import { TicketAnalyticsDto } from '../../dtos/ticket-analytics.dto';
import { asyncForEach, showError } from '../../helper/utils.helper';
import { TicketApiService } from '../../services/ticket-api.service';
import { DashboardChartDto } from '../../dtos/dashboard-chart.dto';
import { ActivityDto } from '../../dtos/activity.dto';
import { Page } from '../../models/page.model';
import { PreloaderService } from '../../services/preloader.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, debounceTime, startWith, delay, tap, switchMap, share, catchError, of, take } from 'rxjs';
import { TicketDto } from '../../dtos/ticket.dto';
import { LabelDto } from '../../dtos/label.dto';
import { GroupDto } from '../../dtos/group.dto';
import { UserDto } from '../../dtos/user.dto';
import { Title } from '@angular/platform-browser';
import { NotificationDto } from '../../dtos/notification.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  analytics: TicketAnalyticsDto | undefined;
  isLoading = true;

  timespan = "1M";
  lastUpdate = new Date();
  userData: any;

  newTicketChart: DashboardChartDto = new DashboardChartDto();

  activities: ActivityDto[] = [];

  notifications: NotificationDto[] = [];
  notificationReadFilter = true;

  groups: GroupDto[] = [];
  users: UserDto[] = [];
  labels: LabelDto[] = [];

  browserLocale = window.navigator.language;
  chartLineColor = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "#603bd6" : "#272654");

  filterForm: FormGroup;
  pageDataObserver: (Observable<Page<TicketDto>> | Observable<any>) | undefined;
  pageDataError$ = new Subject<any>();

  constructor(
    public oidcSecurityService: OidcSecurityService,
    protected ticketApiService: TicketApiService,
    private preloaderService: PreloaderService,
    protected titleService: Title,
    protected router: Router,
  ) {
    this.filterForm = new FormGroup({
      search: new FormControl(),
      page: new FormControl(1),
    });

    this.titleService.setTitle(`EFSsupport System`);
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.oidcSecurityService.getUserData().pipe(take(1)).subscribe((userData) => {
      this.userData = userData;
    });

    this.preloaderService.dataLoaded.subscribe({
      next: () => {
        this.loadData();

        setInterval(() => {
          this.loadData();
        }, 120 * 1000);

        this.ticketApiService.getLabels().subscribe({
          next: (labels) => {
            this.labels = labels;
          },
          error: (error) => {
            showError(error);
          }
        });

        this.ticketApiService.getGroups().subscribe(groups => {
          this.groups = groups;
        });

        this.ticketApiService.getSupportUsers().subscribe(users => {
          this.users = users;
        });
      },
      error: (err) => {
        this.isLoading = false;
        showError(err);
      },
    });
  }

  loadData() {

    this.pageDataObserver = this.filterForm.valueChanges.pipe(
      debounceTime(500),
      startWith(this.filterForm.value),
      delay(0),
      // tap(() => this.isLoading = true),
      switchMap(urlOrFilter => {
        
        urlOrFilter.statusFilters = [
          {
            value: 'open',
            label: 'Offen',
            templateId: 'template',
            data: { color: '#0054a6', filterKey: 'status' },
          },
        ];
        
        urlOrFilter.type = 'none';
        return this.ticketApiService.getAllTickets(urlOrFilter)
      }),
      share(),
      catchError((error) => {
        showError(error);
        this.isLoading = false;
        return of();
      }),
      tap(() => this.isLoading = false),
    );

    this.lastUpdate = new Date();
    this.ticketApiService.getAnalytics(this.timespan).subscribe({
      next: (analytics) => {
        this.isLoading = false;
        this.analytics = analytics;
      },
      error: (err) => {
        this.isLoading = false;
        showError(err)
      }
    });

    this.ticketApiService.getNewTicketsChart(this.timespan).subscribe({
      next: (chart) => {
        const prefilledChartData = [];

        for (let i = 0; i < this.timespan2Days(this.timespan); i++) {
          const date = new Date(new Date(chart.start).getTime() + (i * 86400000));

          date.setHours(1, 0, 0, 0);

          const match = chart.data.find((item: any) => {
            const itemDate = new Date(item._id);
            itemDate.setHours(1, 0, 0, 0);
            return itemDate.getTime() === date.getTime()
          });
          if (match) {
            prefilledChartData.push({
              name: date,
              value: match.count
            });
          } else {
            prefilledChartData.push({
              name: date,
              value: 0
            });
          }
        }
        this.newTicketChart = chart;

        this.newTicketChart.data = [
          {
            name: "Anzahl",
            series: prefilledChartData
          }
        ]
      },
      error: (err) => {
        showError(err);
      },
    });

    this.ticketApiService.getActivities().subscribe({
      next: (data: Page<ActivityDto>) => {
        this.activities = data.data;
      },
      error: (err) => {
        showError(err);
      },
    });

    this.loadNotifications();
  }

  loadNotifications() {
    this.ticketApiService.getNotifications(this.notificationReadFilter ? { filter: 'read', filterValue: !this.notificationReadFilter } : {}).subscribe({
      next: (data: Page<NotificationDto>) => {
        this.notifications = data.data.filter(el => !el.type || el.type === 'systemNotification');
      },
      error: (err) => {
        showError(err);
      },
    });
  }

  setTimespan(span: string) {
    this.timespan = span;
    this.loadData();
  }

  formatDate(value: any) {
    return new Intl.DateTimeFormat('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(value);
  }

  nextPage() {
    const current = this.filterForm.controls['page'].value;
    this.filterForm.patchValue({ page: current + 1 });
  }

  previousPage() {
    const current = this.filterForm.controls['page'].value;
    if (current > 1) {
      this.filterForm.patchValue({ page: current - 1 });
    }
  }

  getGroupName(id: string): string {
    const group = this.groups.find(g => g._id === id);
    if (group) {
      return group.name;
    }
    return '';
  }

  getUserName(id: string): string {
    const group = this.users.find(g => g.user_sso_id === id);
    if (group) {
      return group.name;
    }
    return '';
  }

  getLabel(id: string): LabelDto {
    const label = this.labels.find(g => g._id === id);
    if (label) {
      return label;
    }
    return {
      _id: '',
      name: '',
      color: '',
      hidden: false,
    };
  }

  markNotificationAsRead(notification: NotificationDto) {
    this.ticketApiService.markNotificationAsRead(notification._id).subscribe({
      next: () => {
        this.notifications = this.notifications.filter((el) => el._id !== notification._id);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  async markUnreadNotificationAsRead() {
    this.ticketApiService.markAllNotificationAsRead().subscribe({
      next: () => {
        this.notifications = [];
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  openNotification(notification: NotificationDto) {
    if (!notification.read) {
      this.ticketApiService.markNotificationAsRead(notification._id).subscribe({
        next: () => {
          this.notifications = this.notifications.filter((el) => el._id !== notification._id);
          this.redirectNotification(notification);
        },
        error: (error) => {
          console.log(error);
          this.redirectNotification(notification);
        }
      });
    } else {
      this.redirectNotification(notification);
    }
  }

  redirectNotification(notification: NotificationDto) {
    if (notification.target_type === 'ticket' && !notification.target_sub_id) {
      this.router.navigate(['/admin/tickets', notification.target_id]);
    }

    if (notification.target_type === 'ticket' && notification.target_sub_id) {
      this.router.navigate(['/admin/tickets', notification.target_id], { fragment: notification.target_sub_id });
    }
  }

  timespan2Days(timespan: string): number {
    switch (timespan) {
      case "1D":
        return 2;
      case "7D":
        return 8;
      case "14D":
        return 15;
      case "1M":
        return 31;
      case "3M":
        return 31 * 3;
      case "6M":
        return 31 * 6;
      case "1Y":
        return 31 * 12;
      default:
        return 31;
    }
  }

  ticketType(type: string): string {
    switch (type) {
      case "support_request":
        return "Support-Anfrage";
      case "bug_report":
        return "Bug-Report";
      case "feature_request":
        return "Feature-Wunsch";
      default:
        return "Support-Anfrage";
    }
  }

  updateNotificationSwitch(event: any): void {
    this.notificationReadFilter = event.currentTarget.checked;
    this.loadNotifications();
  }
}
