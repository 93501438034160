import { Component, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, catchError, debounceTime, distinctUntilChanged, filter, of, switchMap, take, tap, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { AppDto } from '../../dtos/app.dto';
import { FaqDto } from '../../dtos/faq.dto';
import { TicketAnswerAttachmentDto } from '../../dtos/ticket-answer-attachment.dto';
import { TicketTemplateDto } from '../../dtos/ticket-template.dto';
import { asyncForEach, showError } from '../../helper/utils.helper';
import { LandingpageApiService } from '../../services/landingpage-api.service';
import { TicketApiService } from '../../services/ticket-api.service';
import { IncidentDto } from '../../dtos/incident.dto.ts';
import { TicketAnswerDto } from '../../dtos/ticket-answer.dto';
import { GroupDto } from '../../dtos/group.dto';
import { UserDto } from '../../dtos/user.dto';
import { UserLookupDto } from '../../dtos/user-lookup.dto';
import { TicketDto } from '../../dtos/ticket.dto';
import * as CustomBuildEditor from 'src/ckeditor5/build/ckeditor';
import { PreloaderService } from '../../services/preloader.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AudioRecordingService } from '../../services/audio-recording.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VideoRecordingService } from '../../services/video-recording.service';
import * as Sentry from "@sentry/angular";

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewTicketComponent implements OnInit, OnDestroy {

  isDark = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : "oxide");
  isDarkContent = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "default");

  step: number = 6;

  step1Selected: AppDto | undefined;

  step1Apps: AppDto[] | undefined;

  step2Faq: FaqDto[] | undefined;

  step3Templates: TicketTemplateDto[] | undefined;

  step3Selected: TicketTemplateDto | undefined;

  loading = false;
  isQuickTicket = false;
  isGeneral = false;

  shouldReadFAQFirst = true;

  userData: any;

  answerFiles: File[] = [];

  groups: GroupDto[] = [];
  users: UserDto[] = [];

  incidents: IncidentDto[] = [];
  filteredIncidents: IncidentDto[] = [];

  quickTicket = {
    subject: '',
    text: '',
    type: '',
    group_id: undefined,
    assigned_to_user_sso_id: undefined,

    different_device_affected: '',
    browser_cleanup: '',

    created_for_user: false,
    created_for_user_visible: false,

    created_for_user_cn: '',
    created_for_user_name: '',
    created_for_user_mail: '',

    priority: 'medium',

    issueSource: 'this_device', // this_device, other_device

    app_id: '',

    metadata: {
      device: '',
      os_version: '',
      browser: '',
    }
  }

  search = false;
  searchTerm = '';
  searchedUsers: UserLookupDto[] = [];

  faqSearchTerm = '';
  faqSearchResults: FaqDto[] = [];
  faqSearchEvent = new Subject<string | undefined>();

  signature: string = "";

  public editor = CustomBuildEditor;

  noTranscriptionFound = false;
  isProcessing = false;
  isRecording = false;
  recordedTime = '';
  blobUrl!: string | null;
  recordingData: any;

  isVideoRecording = false;
  recordedTimeVideo = '';
  blobUrlVideo!: string | null;
  recordingDataVideo: any;

  editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'fontSize',
        'fontColor',
        '|',
        'outdent',
        'indent',
        'alignment',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'codeBlock',
        'code'
      ]
    },
    language: 'de',
  };

  constructor(
    protected landingpageService: LandingpageApiService,
    protected ticketApi: TicketApiService,
    public oidcSecurityService: OidcSecurityService,
    public router: Router,
    private preloadService: PreloaderService,
    private deviceService: DeviceDetectorService,
    private audioRecordingService: AudioRecordingService,
    private videoRecordingService: VideoRecordingService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {

    this.audioRecordingService
      .recordingFailed()
      .subscribe((error) => {
        console.log(error);
        if (error && (error.toString().includes('Permission denied') || error.toString().includes('Permission dismissed'))) {
          Swal.fire({
            icon: 'error',
            title: 'Aufnahme fehlgeschlagen',
            text: 'Der Website fehlt die Berechtigung das Mikrofon aufzunehmen. Bitte erlaube die Aufnahme in den Browsereinstellungen.',
            footer: 'Bei Fragen wenden Sie sich an den EFS-IT-Support unter <a href="mailto:support@efs-ag.com">support@efs-ag.com</a>.'
          });
        }
        this.isRecording = false;
        Sentry.captureException(error);
      });
    this.audioRecordingService
      .getRecordedTime()
      .subscribe(time => {
        this.recordedTime = time

        if (time === '05:00') {
          this.stopRecording();
        }
      });
    this.audioRecordingService.getRecordedBlob().subscribe(data => {
      this.recordingData = data;
      this.blobUrl = URL.createObjectURL(data.blob);
    });

    this.videoRecordingService
      .recordingFailed()
      .subscribe((error) => {
        console.log(error);
        if (error && (error.toString().includes('Permission denied') || error.toString().includes('Permission dismissed'))) {
          Swal.fire({
            icon: 'error',
            title: 'Aufnahme fehlgeschlagen',
            text: 'Der Website fehlt die Berechtigung deinen Desktop aufzunehmen. Bitte erlaube die Aufnahme in den Browsereinstellungen.',
            footer: 'Bei Fragen wenden Sie sich an den EFS-IT-Support unter <a href="mailto:support@efs-ag.com">support@efs-ag.com</a>.'
          });
        }

        this.isVideoRecording = false
        Sentry.captureException(error);
      });
    this.videoRecordingService
      .getRecordedTime()
      .subscribe(time => {
        this.recordedTimeVideo = time

        if (time === '05:00') {
          this.stopVideoRecording();
        }
      });
    this.videoRecordingService.getRecordedBlob().subscribe(data => {
      this.ngZone.run(() => {
        this.recordingDataVideo = data;
        this.isVideoRecording = false;
        console.log("Recording done, blob: ", this.blobUrlVideo);
        this.processVideoRecording();
      });
    });

    this.oidcSecurityService.getUserData().pipe(take(1)).subscribe((userData) => {
      this.userData = userData;
    });

    this.loading = true;

    this.preloadService.dataLoaded.subscribe({
      next: () => {
        this.loadData();
      },
      error: (err) => {
        this.loading = false;
        showError(err);
      },
    });


    if (this.router.url.includes('quickticket') && this.userData.role.includes('efsticket_view')) {
      this.isQuickTicket = true;
    }

    const params = this.router.parseUrl(this.router.url).queryParams;

    if (params["mode"] && params["mode"] === 'feature_request') {
      this.quickTicket.type = 'feature_request';
      this.shouldReadFAQFirst = false;
    }

    if (params["mode"] && params["mode"] === 'bug_report') {
      this.quickTicket.type = 'bug_report';
    }

    if (params["mode"] && params["mode"] === 'support_request') {
      this.quickTicket.type = 'support_request';
    }

    if (params["app"]) {
      this.quickTicket.app_id = params["app"];
    }


    this.startQuickTicket();

    this.faqSearchEvent
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        filter((searchQuery) => searchQuery !== undefined && searchQuery !== '' && this.quickTicket.type !== 'feature_request' && this.quickTicket.app_id !== '' && this.quickTicket.app_id !== 'sonstiges'),
        tap(() => {
          this.loading = true;
        }),
        switchMap((searchQuery) => this.landingpageService.searchFaqs(this.quickTicket.app_id, searchQuery!).pipe(catchError(error => of(error)))),
      ).subscribe((results) => {
        if (results.error) {
          this.loading = false;
          showError(results);
        } else {
          this.faqSearchResults = results;
          this.loading = false;

          if (results.length === 0) {
            this.shouldReadFAQFirst = false;
          }
        }
      });
  }

  loadData() {
    this.landingpageService.getMyApps().subscribe({
      next: (data) => {
        this.step1Apps = data;
        this.loading = false;
      },
      error: (error) => {
        showError(error);
        this.loading = false;
      }
    })

    this.ticketApi.getPublicIncidents().subscribe({
      next: (data) => {
        this.incidents = data;
        this.filteredIncidents = data;
      },
      error: (error) => {
        showError(error);
      }
    });
  }

  updateQuickTicketType(event: any) {
    if (event === 'feature_request') {
      this.shouldReadFAQFirst = false;
    } else if (this.quickTicket.subject === '' || event === '') {
      this.shouldReadFAQFirst = true;
    }

    if (this.quickTicket.app_id == 'sonstiges') {
      this.shouldReadFAQFirst = false;
    }
  }

  updateQuickTicketApp(event: any) {
    if ((event === 'sonstiges' && this.quickTicket.type !== '') || this.quickTicket.type === 'feature_request') {
      this.shouldReadFAQFirst = false;
    } else if (this.quickTicket.subject === '' || event === '') {
      this.shouldReadFAQFirst = true;
    }
  }

  resetUser() {
    this.quickTicket.created_for_user_cn = '';
    this.quickTicket.created_for_user_name = '';
    this.quickTicket.created_for_user_mail = '';
  }

  selectUser(user: UserLookupDto) {
    this.quickTicket.created_for_user_cn = user.cn;
    this.quickTicket.created_for_user_name = user.name;
    this.quickTicket.created_for_user_mail = user.mail;
    this.search = false;
  }

  searchUsers() {

    if (this.searchTerm === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Bitte gebe ein Suchwort ein!',
      })

      return;
    }

    this.ticketApi.searchUsers(this.searchTerm).subscribe({
      next: (data) => {
        this.search = true;
        this.searchedUsers = data;
      },
      error: (error) => {


        if (error.error && error.error.message && error.error.message === 'Users not found') {
          Swal.fire({
            icon: 'warning',
            title: 'Keine Benutzer gefunden',
            text: 'Die Suche ergab keine Treffer.',
          })
        } else {
          showError(error);
        }
      }
    })
  }

  startNormalTicket() {
    this.step = 1;
  }

  startQuickTicket() {

    this.ticketApi.getMySignature().subscribe({
      next: (data) => {
        this.signature = "<p>&nbsp;</p>\n" + data.signature;
        this.quickTicket.text = this.signature;
      },
      error: (err) => {
        showError(err);
      }
    });

    if (this.userData.role.includes('efsticket_view')) {
      // Load groups and users
      this.loading = true;
      this.ticketApi.getGroups().subscribe({
        next: (data) => {
          this.groups = data;
        },
        error: (error) => {
          showError(error);
          this.loading = false;
        }
      })

      this.ticketApi.getSupportUsers().subscribe({
        next: (data) => {
          this.users = data;
        },
        error: (error) => {
          showError(error);
          this.loading = false;
        }
      })
    }

    this.step = 6;
  }

  step1Select(app: AppDto) {
    this.step = 3;
    this.step1Selected = app;
    this.isGeneral = false;
    this.quickTicket.app_id = app._id;
    this.startQuickTicket();

    // this.filteredIncidents = this.incidents.filter((i) => i.apps?.includes(app._id));

    /* this.loading = true;
    this.landingpageService.getFaqByApp(app._id).subscribe({
      next: (data) => {
        this.loading = false;
        this.step2Faq = data.result;
      },
      error: (error) => {
        this.loading = false;
        showError(error);
      },
    }); */
  }

  step1GeneralSelect(category: string) {
    this.isGeneral = true;
    this.step = 3;
    this.shouldReadFAQFirst = false;
    this.quickTicket.app_id = category;
    this.startQuickTicket();

    // this.filteredIncidents = this.incidents.filter((i) => !i.apps || i.apps?.length === 0);

    /* this.loading = true;
    this.ticketApi.getTicketTemplatesByCategory(category).subscribe({
      next: (data) => {
        this.loading = false;
        this.step3Templates = data;
      },
      error: (error) => {
        this.loading = false;
        showError(error);
      }
    }); */
  }

  stepBack() {

    if (this.isGeneral && this.step === 3) {
      this.step = 1;
    } else {
      this.step--;
    }
  }

  step2Select() {
    this.step = 3;
    this.startQuickTicket();

    /* this.loading = true;
    this.ticketApi.getTicketTemplatesByAppId(this.step1Selected!._id).subscribe({
      next: (data) => {
        this.loading = false;
        this.step3Templates = data;

        if (this.step3Templates.length === 0) {
          this.startQuickTicket();
        }

      },
      error: (error) => {
        this.loading = false;
        showError(error);
      }
    }); */
  }

  step3Select(template: TicketTemplateDto) {
    this.step = 4;
    this.step3Selected = template;
  }

  step4Select() {

    // Validate the answes

    let checked = true;

    this.step3Selected?.fields.every((field) => {
      if (field.required && (field.content === undefined || field.content === null || field.content == "")) {
        Swal.fire({
          icon: 'warning',
          html: `Bitte fülle das Pflichtfeld "<strong>${field.name}</strong>" aus.`,
        })
        checked = false;
        return false;
      }

      if (field.regex && field.content && !field.content.match(field.regex)) {
        Swal.fire({
          icon: 'warning',
          html: `Das Feld "<strong>${field.name}</strong>" entspricht nicht dem geforderten Format.`,
        })
        checked = false;
        return false;
      }

      return true;
    });

    if (checked) {
      this.step = 5;
    }
  }

  fileUploadEvent(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.answerFiles.push(event.target.files[i]);
    }
  }

  removeAttachment(attachment: File) {
    this.answerFiles = this.answerFiles.filter((file) => file !== attachment);
  }

  checkQuickTicket() {
    if (this.quickTicket.subject === '' || this.quickTicket.text === '' || this.quickTicket.text === this.signature) {
      Swal.fire({
        icon: 'warning',
        html: `Bitte fülle alle Felder aus.`,
      })
      return false;
    }

    if (this.quickTicket.created_for_user && this.quickTicket.created_for_user_cn === '') {
      Swal.fire({
        icon: 'warning',
        html: `Bitte wähle einen Benutzer aus, für den du das Anliegen erstellen möchtest.`,
      })
      return false;
    }


    if (!this.isQuickTicket && this.quickTicket.issueSource === 'other_device' && (this.quickTicket.metadata.browser === '' || this.quickTicket.metadata.os_version === '' || this.quickTicket.metadata.device === '')) {
      Swal.fire({
        icon: 'warning',
        html: `Bitte fülle alle Pflichtfelder aus.`,
      })
      return false;
    }

    if (!this.isQuickTicket && this.quickTicket.type === 'bug_report' && (this.quickTicket.different_device_affected === '' || this.quickTicket.browser_cleanup === '')) {
      Swal.fire({
        icon: 'warning',
        html: `Bitte fülle alle Pflichtfelder aus.`,
      })
      return false;
    }

    if (this.quickTicket.subject.length > 100) {
      Swal.fire({
        icon: 'warning',
        html: `Der Betreff darf maximal 100 Zeichen lang sein.`,
      })
      return false;
    }

    return true;
  }

  async submitQuickTicket() {
    if (this.checkQuickTicket()) {
      this.loading = true;

      Swal.fire({
        title: 'Bitte warten ...',
        text: 'Der Vorgang kann einige Minuten in Anspruch nehmen.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let uploadedData: TicketAnswerAttachmentDto[] = [];

      if (this.answerFiles.length > 0) {
        await asyncForEach(this.answerFiles, async (file: File) => {
          const uploadedDataResponse = await this.ticketApi.uploadFiles(file).toPromise().catch((error) => {
            showError(error);
            this.loading = false;

            throw new Error('Upload failed!');
          });

          if (uploadedDataResponse) {
            uploadedData.push(uploadedDataResponse)
          }
        });
      }


      const newTicketDto: TicketDto = {
        author_email: this.userData.email,
        author_name: this.userData.name,
        author_user_sso_id: this.userData.nickname,
        subject: this.quickTicket.subject,
        type: this.quickTicket.type,
        source: 'landingpage',
        language: 'de',
        priority: 'medium',
        assigned_to_user_sso_id: this.quickTicket.assigned_to_user_sso_id,
        group_id: this.quickTicket.group_id,
      };

      if (!this.isQuickTicket && this.quickTicket.app_id !== 'sonstiges') {
        newTicketDto.app_id = this.quickTicket.app_id;
      }

      if (!this.isQuickTicket && this.quickTicket.priority !== 'medium') {
        newTicketDto.priority = this.quickTicket.priority;
      }

      if (this.quickTicket.created_for_user) {
        /**
         * If the ticket is created for another user, we need to set ticket author = user and created_for_user = supporter
         */

        newTicketDto.created_for_user_cn = this.userData.nickname;
        newTicketDto.created_for_user_name = this.userData.name;
        newTicketDto.created_for_user_mail = this.userData.email;

        newTicketDto.author_user_sso_id = this.quickTicket.created_for_user_cn,
          newTicketDto.author_name = this.quickTicket.created_for_user_name,
          newTicketDto.author_email = this.quickTicket.created_for_user_mail,

          newTicketDto.created_for_user_visible = this.quickTicket.created_for_user_visible;
        newTicketDto.created_for_user = true;
      }

      const ticket = await this.ticketApi.submitTicket(newTicketDto).toPromise().catch(error => {
        showError(error);
        throw new Error('Failed to create ticket');
      })

      if (ticket) {

        const newTicketAnswerDto: TicketAnswerDto = {
          text: this.quickTicket.text,
          attachments: uploadedData,
          type: 'customerAnswer',
          author_name: this.userData.name,
          author_email: this.userData.email,
          author_user_sso_id: this.userData.nickname,
          source: 'landingpage',
          template_data: [],
        };

        if (!this.isQuickTicket) {
          if (this.quickTicket.issueSource === 'other_device') {
            newTicketAnswerDto.template_data = [
              {
                name: "Browser",
                display_name: "Informationen über den Browser",
                type: "text",
                required: false,
                options: [],
                regex: "",
                content: this.quickTicket.metadata.browser,
              },
              {
                name: "Betriebssystem",
                display_name: "Informationen über das Betriebssystem",
                type: "text",
                required: false,
                options: [],
                regex: "",
                content: this.quickTicket.metadata.os_version,
              },
              {
                name: "Gerät",
                display_name: "Informationen über das Gerät",
                type: "text",
                required: false,
                options: [],
                regex: "",
                content: this.quickTicket.metadata.device,
              }
            ]
          } else {
            // Collect metatdata of the user
            const deviceInfo = this.deviceService.getDeviceInfo();
            newTicketAnswerDto.template_data = [
              {
                name: "Browser",
                display_name: "Informationen über den Browser",
                type: "text",
                required: false,
                options: [],
                regex: "",
                content: `${deviceInfo.browser} ${deviceInfo.browser_version}`,
              },
              {
                name: "Betriebssystem",
                display_name: "Informationen über das Betriebssystem",
                type: "text",
                required: false,
                options: [],
                regex: "",
                content: `${deviceInfo.os} ${deviceInfo.os_version}`,
              },
              {
                name: "Gerät",
                display_name: "Informationen über das Gerät",
                type: "text",
                required: false,
                options: [],
                regex: "",
                content: `${deviceInfo.device} ${deviceInfo.deviceType.toLocaleUpperCase()}`,
              },
              {
                name: "UserAgent",
                display_name: "Der UserAgent des Browsers",
                type: "text",
                required: false,
                options: [],
                regex: "",
                content: deviceInfo.userAgent,
              }
            ]
          }

          if (this.quickTicket.type === 'bug_report') {
            newTicketAnswerDto.template_data.push({
              name: "Mehrere Geräte betroffen?",
              display_name: "Sind von dem Problem mehrere Geräte betroffen?",
              type: "text",
              required: false,
              options: [],
              regex: "",
              content: this.quickTicket.different_device_affected,
            });

            newTicketAnswerDto.template_data.push({
              name: "Cache und Cookies gelöscht?",
              display_name: "Wurde der Cache und die Cookies in der Browser-Session gelöscht?",
              type: "text",
              required: false,
              options: [],
              regex: "",
              content: this.quickTicket.browser_cleanup,
            });
          }
        }

        if (this.quickTicket.created_for_user) {
          /**
           * If the ticket is created for another user, we need to set ticket author = user and created_for_user = supporter
           */

          newTicketAnswerDto.created_for_user_cn = this.userData.nickname;
          newTicketAnswerDto.created_for_user_name = this.userData.name;
          newTicketAnswerDto.created_for_user_mail = this.userData.email;

          newTicketAnswerDto.author_user_sso_id = this.quickTicket.created_for_user_cn,
            newTicketAnswerDto.author_name = this.quickTicket.created_for_user_name,
            newTicketAnswerDto.author_email = this.quickTicket.created_for_user_mail,

            newTicketAnswerDto.created_for_user_visible = this.quickTicket.created_for_user_visible;
          newTicketAnswerDto.created_for_user = true;
        }

        this.ticketApi.answerTicket(ticket._id!, newTicketAnswerDto).subscribe({
          next: (data) => {
            Swal.fire({
              html: `Ihre Anfrage wurde erfolgreich an uns übermittelt.<br>Wir versuchen Sie schnellstmöglich bei Ihrem Anliegen zu unterstützen.<br><br>Ihr EFS-IT-Support`,
              icon: 'success',
            }).then(() => {
              this.loading = false;

              if (this.userData.role.includes('efsticket_view')) {
                this.router.navigate(['/admin/tickets', ticket._id!])
              } else {
                this.router.navigate(['/ticket', ticket._id!])
              }
            })
          },
          error: (error) => {
            showError(error);
            this.loading = false;
          },
        })
      }
    }
  }

  async submit() {

    this.loading = true;

    let uploadedData: TicketAnswerAttachmentDto[] = [];

    Swal.fire({
      title: 'Bitte warten ...',
      text: 'Der Vorgang kann einige Minuten in Anspruch nehmen.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (this.answerFiles.length > 0) {
      await asyncForEach(this.answerFiles, async (file: File) => {
        const uploadedDataResponse = await this.ticketApi.uploadFiles(file).toPromise().catch((error) => {
          showError(error);
          this.loading = false;

          throw new Error('Upload failed!');
        });

        if (uploadedDataResponse) {
          uploadedData.push(uploadedDataResponse)
        }
      });
    }

    const ticket = await this.ticketApi.submitTicket({
      app_id: this.step1Selected!._id,
      author_email: this.userData.email,
      author_name: this.userData.name,
      author_user_sso_id: this.userData.nickname,
      subject: this.step3Selected!.name,
      source: 'landingpage',
      language: 'de',
      priority: 'medium',
    }).toPromise().catch(error => {
      showError(error);
      throw new Error('Failed to create ticket');
    })

    if (ticket) {
      this.ticketApi.answerTicket(ticket._id!, {
        text: '',
        attachments: uploadedData,
        type: 'initialAnswer',
        author_name: this.userData.name,
        author_email: this.userData.email,
        author_user_sso_id: this.userData.nickname,
        source: 'landingpage',
        template_data: this.step3Selected?.fields!,
      }).subscribe({
        next: (data) => {
          Swal.fire({
            html: `Ihre Anfrage wurde erfolgreich an uns übermittelt.<br>Wir versuchen Sie schnellstmöglich bei Ihrem Anliegen zu unterstützen.<br><br>Ihr EFS-IT-Support`,
            icon: 'success',
          }).then(() => {
            this.loading = false;
            this.router.navigate(['/ticket', ticket._id!])
          })
        },
        error: (error) => {
          showError(error);
          this.loading = false;
        },
      })
    }
  }

  renderState(state: string) {
    if (state === 'NEW') {
      return 'Störung aktiv';
    } else if (state === 'UNDER_INVESTIGATION') {
      return 'Störung in Prüfung';
    } else if (state === 'IN_PROGRESS') {
      return 'Störung in Bearbeitung';
    } else if (state === 'RESOLVED') {
      return 'Störung behoben';
    } else if (state === 'CLOSED') {
      return 'Störung geschlossen';
    } else {
      return 'Unbekannt';
    }
  }

  renderSeverity(severity: string) {
    if (severity === 'PARTIAL_OUTAGE') {
      return 'Teilausfall';
    } else if (severity === 'OUTAGE') {
      return 'severity';
    } else if (severity === 'DEGRADED_PERFORMANCE') {
      return 'System verlangsamt';
    } else if (severity === 'UNDER_MAINTENANCE') {
      return 'Wartung';
    } else if (severity === 'OPERATIONAL') {
      return 'Alle Systeme normal';
    } else {
      return 'Unbekannt';
    }
  }

  noMatchingFaqs() {
    this.faqSearchResults = [];
    this.shouldReadFAQFirst = false;
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  processRecording() {
    this.isProcessing = true;
    const file = new File([this.recordingData.blob], `Sprachmitteilung_${new Date().toLocaleString().replace(', ', '_').replaceAll('.', '-').replaceAll(':', '-')}.mp3`, { type: 'audio/webm' });

    console.log(file);

    this.answerFiles.push(file);
    this.clearRecordedData();

    this.ticketApi.transcribeAudio(file, this.recordingData.duration).subscribe({
      next: (data) => {
        this.isProcessing = false;

        if (data.text === '') {
          this.noTranscriptionFound = true;
          setTimeout(() => {
            this.noTranscriptionFound = false;
          }, 10000);
        } else {
          if (this.quickTicket.text !== this.signature) {
            this.quickTicket.text = data.text + '<br>' + this.quickTicket.text;
          } else {
            this.quickTicket.text = data.text + this.signature;
          }
        }
      }, error: (error) => {
        this.isProcessing = false;
        showError(error);
      }
    });

  }

  startVideoRecording() {
    if (!this.isVideoRecording) {
      this.isVideoRecording = true;
      this.videoRecordingService.startRecording();
    }
  }

  abortVideoRecording() {
    if (this.isVideoRecording) {
      this.isVideoRecording = false;
      this.videoRecordingService.abortRecording();
    }
  }

  stopVideoRecording() {
    if (this.isVideoRecording) {
      this.videoRecordingService.stopRecording();
      this.isVideoRecording = false;
    }
  }

  clearVideoRecordedData() {
    this.blobUrlVideo = null;
    this.recordingDataVideo = null;
  }

  processVideoRecording() {
    console.log("Processing video recording", this.recordingDataVideo.blob);
    const file = new File([this.recordingDataVideo.blob], `Desktopaufnahme_${new Date().toLocaleString().replace(', ', '_').replaceAll('.', '-').replaceAll(':', '-')}.mp4`, { type: 'video/mp4' });
    this.answerFiles.push(file);
    this.clearVideoRecordedData();

    Swal.fire({
      title: 'Videoaufnahme',
      text: 'Die Aufnahme wurde erfolgreich abgeschlossen und ist dem Anliegen als Anhang hinzugefügt worden.',
      icon: 'success',
    })
  }

  getBlobUrl(file: File): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  browserCleanupHelp() {
    Swal.fire({
      title: 'Browser Cache und Cookies löschen',
      width: '70%',
      animation: false,
      html: `<section style="text-align: justify;"><p>Nach Updates oder bei Fehlern kann es passieren, dass der Browser Informationen oder Programmteile in den Zwischenspeicher lädt und diese Daten statt den korrekten Daten verwendet. In diesem Fall ist es Sinnvoll den Browser Cache und die Cookies zu löschen, sodass der Browser definitv die korrekte und aktuellste Version der Anwendung verwendet.</p><p>Nachfolgend wird erklärt, wie dieser Vorgang bei Google Chrome und Safari durchzuführen ist.</p><h2>Google Chrome</h2><p>Folge diesen Schritten, um Cookies und Cache in Google Chrome zu löschen:</p><ol><li>Öffne Google Chrome.</li><li>Klicke rechts oben auf das Dreipunkt-Menü (⋮).</li><li>Klicke auf "Einstellungen".</li><li>Scrolle nach unten und klicke auf "Erweitert".</li><li>Unter "Datenschutz und Sicherheit" wähle "Browserdaten löschen".</li><li>Ein Dialogfenster erscheint. Wählen hier den Zeitraum (z.B. "Gesamte Zeit").</li><li>Stelle sicher, dass die Kästchen für "Cookies und andere Websitedaten" und "Bilder und Dateien im Cache" markiert sind.</li><li>Klicke auf "Daten löschen".</li></ol></section><section style="text-align: justify;"><h2>Safari</h2><p>Folge diesen Schritten, um Cookies und Cache in Safari zu löschen:</p><ol><li>Öffne Safari.</li><li>Klicke in der Menüleiste auf "Safari" und wähle "Einstellungen".</li><li>Klicke auf den Tab "Datenschutz".</li><li>Klicke auf "Verwalten von Website-Daten…".</li><li>Klicke auf "Alle entfernen", um alle Daten zu löschen oder suche eine bestimmte Website, um nur deren Cookies zu entfernen.</li><li>Klicke auf "Jetzt entfernen" im angezeigten Dialogfenster.</li><li>Zurück im Einstellungsfenster von Safari, kannst du zusätzlich zum Leeren des Caches auf den Tab "Erweitert" gehen und das Kästchen "Entwickler-Menü in der Menüleiste anzeigen" aktivieren.</li><li>Wähle im Menü "Entwickler" die Option "Caches leeren".</li></ol></section>`
    })
  }
}
