<div class="page-body mt-3">

    <div class="container mt-6 mb-6" *ngIf="isLoading">
        <div class="text-center ms-3 me-3">
            <div class="mb-3">
            </div>
            <div class="text-muted mb-3">Lade Daten ...</div>
            <div class="progress progress-sm">
                <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-lg-16">
                <div class="row row-cards" *ngIf="analytics !== undefined && !isLoading">

                    <div class="col-lg-12">
                        <div class="btn-list">
                            <button class="btn btn-xs btn-outline-efs" (click)="setTimespan('1D')"
                                *ngIf="timespan !== '1D'">1
                                D</button>
                            <button class="btn btn-xs btn-efs" (click)="setTimespan('1D')" *ngIf="timespan === '1D'">1
                                D</button>

                            <button class="btn btn-xs btn-outline-efs" (click)="setTimespan('7D')"
                                *ngIf="timespan !== '7D'">7
                                D</button>
                            <button class="btn btn-xs btn-efs" (click)="setTimespan('7D')" *ngIf="timespan === '7D'">7
                                D</button>

                            <button class="btn btn-xs btn-outline-efs" (click)="setTimespan('14D')"
                                *ngIf="timespan !== '14D'">14 D</button>
                            <button class="btn btn-xs btn-efs" (click)="setTimespan('14D')"
                                *ngIf="timespan === '14D'">14
                                D</button>

                            <button class="btn btn-xs btn-outline-efs" (click)="setTimespan('1M')"
                                *ngIf="timespan !== '1M'">1
                                M</button>
                            <button class="btn btn-xs btn-efs" (click)="setTimespan('1M')" *ngIf="timespan === '1M'">1
                                M</button>

                            <button class="btn btn-xs btn-outline-efs" (click)="setTimespan('3M')"
                                *ngIf="timespan !== '3M'">3
                                M</button>
                            <button class="btn btn-xs btn-efs" (click)="setTimespan('3M')" *ngIf="timespan === '3M'">3
                                M</button>

                            <button class="btn btn-xs btn-outline-efs" (click)="setTimespan('6M')"
                                *ngIf="timespan !== '6M'">6
                                M</button>
                            <button class="btn btn-xs btn-efs" (click)="setTimespan('6M')" *ngIf="timespan === '6M'">6
                                M</button>

                            <button class="btn btn-xs btn-outline-efs" (click)="setTimespan('1Y')"
                                *ngIf="timespan !== '1Y'">1
                                Y</button>
                            <button class="btn btn-xs btn-efs" (click)="setTimespan('1Y')" *ngIf="timespan === '1Y'">1
                                Y</button>
                            <div class="ms-auto text-end text-muted small">Aktualisiert vor {{ lastUpdate | amLocale:
                                browserLocale | amTimeAgo:true }}</div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 col-6">
                        <div class="card card-sm h-100">
                            <div class="card-body">
                                <div class="row align-items-center h-100">
                                    <div class="col-auto">
                                        <span class="bg-primary text-white avatar">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-files" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M15 3v4a1 1 0 0 0 1 1h4"></path>
                                                <path
                                                    d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z">
                                                </path>
                                                <path
                                                    d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="font-weight-medium link" [routerLink]="'/admin/tickets'">
                                            <strong>{{ analytics.tickets_open }}</strong> Offen
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 col-6">
                        <div class="card card-sm h-100">
                            <div class="card-body">
                                <div class="row align-items-center h-100">
                                    <div class="col-auto">
                                        <span class="bg-primary text-white avatar">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-hourglass" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M6.5 7h11"></path>
                                                <path d="M6.5 17h11"></path>
                                                <path d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z">
                                                </path>
                                                <path d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="font-weight-medium link" [routerLink]="'/admin/tickets/inprogress'">
                                            <strong>{{ analytics.tickets_inprogess }}</strong> In Bearbeitung
                                        </div>
                                        <div class="font-weight-medium link" [routerLink]="'/admin/tickets/waiting'">
                                            <strong>{{ analytics.tickets_moreinformation }}</strong> Wartend
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 col-6">
                        <div class="card card-sm h-100">
                            <div class="card-body">
                                <div class="row align-items-center h-100">
                                    <div class="col-auto">
                                        <span class="bg-primary text-white avatar">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-brand-stackshare" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M19 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                <path d="M19 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                <path d="M5 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                <path d="M7 12h3l3.5 6h3.5"></path>
                                                <path d="M17 6h-3.5l-3.5 6"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="font-weight-medium link" [routerLink]="'/admin/tickets/mail'">
                                            <strong>{{ analytics.tickets_mail }}</strong> Per Mail
                                        </div>
                                        <div class="font-weight-medium link"
                                            [routerLink]="'/admin/tickets/landingpage'">
                                            <strong>{{ analytics.tickets_landingpage }}</strong> EFSsupport
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 col-6">
                        <div class="card card-sm h-100">
                            <div class="card-body">
                                <div class="row align-items-center h-100">
                                    <div class="col-auto">
                                        <span class="bg-warning text-white avatar">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-alert-triangle" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path
                                                    d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z">
                                                </path>
                                                <path d="M12 9v4"></path>
                                                <path d="M12 17h.01"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="font-weight-medium link" [routerLink]="'/admin/tickets/escalated'">
                                            <strong>{{ analytics.tickets_escalated_time }}</strong> Eskaliert (Zeit)

                                        </div>
                                        <div class="font-weight-medium link" [routerLink]="'/admin/tickets/escalated'">
                                            <strong>{{ analytics.tickets_escalated_user }}</strong> Eskaliert (VP)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 col-6">
                        <div class="card card-sm h-100">
                            <div class="card-body">
                                <div class="row align-items-center h-100">
                                    <div class="col-auto">
                                        <span class="bg-primary text-white avatar">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-chart-dots" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 3v18h18"></path>
                                                <path d="M9 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                <path d="M19 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                <path d="M14 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                                <path d="M10.16 10.62l2.34 2.88"></path>
                                                <path d="M15.088 13.328l2.837 -4.586"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="font-weight-medium">
                                            <strong>{{ analytics.tickets_today }}</strong> neu (heute)
                                        </div>
                                        <div class="font-weight-medium">
                                            <strong>{{ analytics.tickets_yesterday }}</strong> neu (gestern)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2 col-6">
                        <div class="card card-sm h-100">
                            <div class="card-body">
                                <div class="row align-items-center h-100">
                                    <div class="col-auto">
                                        <span class="bg-primary text-white avatar">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-clock-hour-2" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                <path d="M12 12l3 -2"></path>
                                                <path d="M12 7v5"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="font-weight-medium">
                                            <strong>{{ analytics.avgAnswerTime / 1000 | convertseconds }}</strong> ~
                                            Antwort
                                        </div>
                                        <div class="font-weight-medium">
                                            <strong>{{ analytics.avgResolveTime / 1000 | convertseconds }}</strong> ~
                                            Lösung
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6" *ngIf="!isLoading && activities.length > 0">
                <div class="card" style="height: 23rem">
                    <div class="card-header card-small">
                        <h3 class="card-title">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-timeline d-none d-sm-inline-block"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path
                                    d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                                <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                                <path d="M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727" />
                                <path d="M3 6.727a11.05 11.05 0 0 1 2.792 -3.727" />
                            </svg>
                            Benachrichtigungen
                        </h3>

                        <div class="card-actions" style="display: inline-flex">

                            <a [ngbTooltip]="'Alle ungelesenen Benachrichtigungen als gelesen markieren'"
                                *ngIf="notificationReadFilter" (click)="markUnreadNotificationAsRead()"
                                class="link me-2"><svg xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-trash" width="76" height="76"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M4 7l16 0" />
                                    <path d="M10 11l0 6" />
                                    <path d="M14 11l0 6" />
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                </svg></a>

                            <span class="text-muted me-2">Ungelesene</span>
                            <label class="form-check form-switch mb-0 me-1">
                                <input (change)="updateNotificationSwitch($event)" class="form-check-input"
                                    type="checkbox" checked>
                            </label>
                        </div>

                    </div>
                    <div class="card-body card-body-scrollable card-body-scrollable-shadow pt-2 ps-3"
                        *ngIf="notifications.length > 0">
                        <div class="divide-y">

                            <div *ngFor="let notification of notifications">
                                <div class="row">
                                    <div class="col link" (click)="openNotification(notification)">
                                        <span [innerHTML]="notification.text"></span>
                                    </div>
                                    <div class="col-auto align-self-center"
                                        *ngIf="notification.target_type === 'ticket'">
                                        <span class="text-muted mt-1 small me-2"
                                            [ngbTooltip]="notification.date | date: 'dd.MM.yyy HH:mm'">Vor {{
                                            notification.date | amLocale: browserLocale | amTimeAgo:true }}</span>

                                        <a [ngbTooltip]="'Als gelesen markieren'" *ngIf="!notification.read"
                                            (click)="markNotificationAsRead(notification)" class="link ms-1"><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-trash" width="76" height="76"
                                                viewBox="0 0 24 24" stroke-width="1.5" stroke="#9e9e9e" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 7l16 0" />
                                                <path d="M10 11l0 6" />
                                                <path d="M14 11l0 6" />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg></a>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="card-body" *ngIf="notifications.length === 0">

                        <div class="h-100">
                            <div class="full-center">
                                <h1>Alles erledigt!</h1>
                                <p *ngIf="notificationReadFilter">
                                    Es gibt keine ungelesenen Benachrichtigungen für dich.
                                </p>

                                <p *ngIf="!notificationReadFilter">
                                    Es gibt keine Benachrichtigungen für dich.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-6" *ngIf="!isLoading && activities.length > 0">
                <div class="card" style="height: 23rem">
                    <div class="card-header card-small">
                        <h3 class="card-title">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-timeline"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M4 16l6 -7l5 5l5 -6"></path>
                                <path d="M15 14m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                <path d="M10 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                <path d="M4 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                <path d="M20 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                            </svg>
                            Aktuelle Ereignisse
                        </h3>
                    </div>
                    <div class="card-body card-body-scrollable card-body-scrollable-shadow pt-2 ps-3"
                        *ngIf="activities.length > 0">
                        <div class="divide-y">

                            <div *ngFor="let activity of activities">
                                <div class="row">
                                    <div class="col">
                                        <div [innerHTML]="activity.text"></div>
                                    </div>
                                    <div class="col-auto align-self-center" *ngIf="activity.target_type === 'ticket'">
                                        <span class="text-muted mt-1 small me-2"
                                            [ngbTooltip]="activity.date | date: 'dd.MM.yyy HH:mm'">Vor {{ activity.date
                                            | amLocale: browserLocale | amTimeAgo:true }}</span>
                                        <a class="link" *ngIf="!activity.target_sub_id"
                                            [routerLink]="['/admin/tickets', activity.target_id]"><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-external-link" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6">
                                                </path>
                                                <path d="M11 13l9 -9"></path>
                                                <path d="M15 4h5v5"></path>
                                            </svg></a>

                                        <a class="link" *ngIf="activity.target_sub_id"
                                            [routerLink]="['/admin/tickets', activity.target_id]"
                                            [fragment]="activity.target_sub_id"><svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-external-link" width="24"
                                                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6">
                                                </path>
                                                <path d="M11 13l9 -9"></path>
                                                <path d="M15 4h5v5"></path>
                                            </svg></a>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <a [routerLink]="['/admin/activities']">Alle Ereignisse anzeigen</a>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12" *ngIf="!isLoading && (pageDataObserver | async) as pageData;">
                <div class="card">
                    <div class="card-header card-small">
                        <h3 class="card-title">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-files"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                            </svg>

                            Schnell-Übersicht offener Anliegen aus allen Bereichen
                        </h3>

                        <div class="card-actions">
                            <ul class="pagination m-0 ms-auto">
                                <li class="page-item">
                                    <button class="page-link {{ (pageData.currentPage <= 1 ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage <= 1" (click)="previousPage()" tabindex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 6l-6 6l6 6" />
                                        </svg>
                                        Zurück
                                    </button>
                                </li>
                                <li class="page-item"><button class="page-link" href="#" [disabled]="true">Seite {{
                                        pageData.currentPage
                                        }} / {{ pageData.maxPages() }}</button></li>
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage >= pageData.maxPages()" (click)="nextPage()">
                                        Weiter
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <table class="table table-vcenter table-mobile-lg card-table">
                        <thead>
                            <tr>
                                <th class="w-1">ID</th>
                                <th>Betreff</th>
                                <th>Zuständigkeit / Zuordnung</th>
                                <th>Status / Priorität</th>
                                <th>Letzte Antwort</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ticket of pageData.data"
                                [className]="ticket.read_by && !ticket.read_by.includes(userData.nickname) ? 'unread link' : 'link'">
                                <td data-label="Anliegen-ID" class="text-nowrap">
                                    <span class="text-muted">
                                        <span [ngbTooltip]="'Ungelesen, oder neue Antwort vorhanden'"
                                            class="badge bg-efs me-2"
                                            *ngIf="ticket.read_by && !ticket.read_by.includes(userData.nickname)"></span>
                                        <a target="_blank" [routerLink]="['/admin/tickets/', ticket._id]">#{{ticket.ticket_number }}</a>
                                    </span>
                                </td>
                                <td data-label="Betreff" [routerLink]="['/admin/tickets/', ticket._id]">
                                    {{ ticket.subject }}<br>
                                    <span class="text-muted"
                                        title="{{ ticket.author_user_sso_id ? ticket.author_user_sso_id : '' }}">{{
                                        ticket.author_name ? ticket.author_name : 'Name unbekannt' }}</span>
                                </td>


                                <td data-label="Zuständigkeit/Zuordnung" [routerLink]="['/admin/tickets/', ticket._id]">
                                    {{ getGroupName(ticket.group_id) }}
                                    <span *ngIf="ticket.assigned_to_user_sso_id">({{
                                        getUserName(ticket.assigned_to_user_sso_id) }})</span>
                                </td>

                                <td data-label="Status" [routerLink]="['/admin/tickets/', ticket._id]">
                                    <div class="btn-list flex-nowrap">
                                        <span *ngIf="ticket.status === 'open'"
                                            class="status status-primary nowrap">Offen</span>
                                        <span *ngIf="ticket.status === 'closed'"
                                            class="status status-secondary nowrap">Geschlossen</span>
                                        <span *ngIf="ticket.status === 'answered'"
                                            class="status status-success nowrap">Beantwortet</span>
                                        <span *ngIf="ticket.status === 'in_progress'"
                                            class="status status-success nowrap">In Bearbeitung</span>
                                        <span *ngIf="ticket.status === 'more_information_needed'"
                                            class="status status-warning nowrap">Weitere Info. benötigt</span>

                                        <span *ngIf="ticket.internal_status === 'merged'"
                                            [ngbTooltip]="'Das Anliegen wurde mit einem anderen Anliegen zusammengeführt.'"
                                            class="status status-cyan nowrap">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="icon icon-tabler icon-tabler-arrow-merge" width="24" height="24"
                                                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M8 7l4 -4l4 4"></path>
                                                <path
                                                    d="M12 3v5.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v1.394">
                                                </path>
                                                <path
                                                    d="M12 3v5.394a6.737 6.737 0 0 0 3 5.606a6.737 6.737 0 0 1 3 5.606v1.394">
                                                </path>
                                            </svg></span>

                                        <span
                                            [ngbTooltip]="'Das Anliegen wurde vom Benutzer, oder automatisch wegen Inaktivität eskaliert.'"
                                            *ngIf="ticket.internal_status === 'escalated'"
                                            class="status status-danger nowrap">Eskaliert</span>

                                        <span *ngIf="ticket.priority === 'high'"
                                            class="status status-warning">Hoch</span>
                                        <span *ngIf="ticket.priority === 'asap'"
                                            class="status status-danger">ASAP</span>
                                    </div>
                                </td>

                                <td data-label="Letzte Antwort" [routerLink]="['/admin/tickets/', ticket._id]">
                                    <span>{{ ticket.last_answer_at | date: 'dd.MM.yyyy HH:mm' }}</span><br>
                                    <span class="text-muted ms-1" *ngIf="ticket.last_answer_author_name">{{
                                        ticket.last_answer_author_name }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-lg-12" *ngIf="!isLoading && newTicketChart.data.length > 0">
                <div class="card">
                    <div class="card-header card-small">
                        <h3 class="card-title">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-histogram"
                                width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M3 3v18h18"></path>
                                <path d="M20 18v3"></path>
                                <path d="M16 16v5"></path>
                                <path d="M12 13v8"></path>
                                <path d="M8 16v5"></path>
                                <path d="M3 11c6 0 5 -5 9 -5s3 5 9 5"></path>
                            </svg> Neue Anliegen
                        </h3>
                    </div>
                    <div class="card-body p-0" style="overflow: hidden;">
                        <ngx-charts-line-chart [customColors]="[{ name: 'Anzahl', value: chartLineColor }]"
                            [results]="newTicketChart.data" [legend]="false" [showXAxisLabel]="false"
                            [showYAxisLabel]="false" [xAxis]="true" [yAxis]="true" [xAxisLabel]="'Datum'"
                            [yAxisLabel]="'Anzahl'" [rotateXAxisTicks]="true" [xAxisTickFormatting]="formatDate"
                            [timeline]="false">
                            <ng-template #tooltipTemplate let-model="model">
                                {{ model.name | date: 'dd.MM.YYY' }}: {{ model.value }} Anfragen
                            </ng-template>

                            <ng-template #seriesTooltipTemplate let-model="model">
                                {{ model[0].name | date: 'dd.MM.YYY' }}: {{ model[0].value }} Anfragen
                            </ng-template>
                        </ngx-charts-line-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>