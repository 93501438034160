import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { UserDto } from "../dtos/user.dto";
import { environment } from "../../environments/environment";
import { TicketOverviewDto } from "../dtos/ticket-overview.dto";
import { PagedTicketDto } from "../dtos/paged-ticket.dto";
import { ApiIndexDto } from "../dtos/api-index.dto";
import { PagedNotificationDto } from "../dtos/paged-notification.dto";
import { NotificationDto } from "../dtos/notification.dto";

@Injectable({ providedIn: 'root' })
export class CheckAuthService {

    constructor(
        public http: HttpClient,
    ) {
    }

    public dataLoaded: ReplaySubject<any> = new ReplaySubject();

    getMyTicketsUnpagedAllTypes(token = "", search: string): Observable<PagedTicketDto> {
        return this.http
            .get<PagedTicketDto>(
                this.apiUrl(`ticket?search=${encodeURIComponent(search)}&sort=ticket_number&sortOrder=desc&type=none`),
                {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${token}`,
                    }),
                },
            );
    }

    markNotificationAsRead(notificationId: string, token = ""): Observable<NotificationDto> {
        return this.http
            .patch<NotificationDto>(
                this.apiUrl(`notification/read/${notificationId}`),
                {},
                {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${token}`,
                    }),
                },
            );
    }

    markAllNotificationAsRead(token = ""): Observable<NotificationDto[]> {
        return this.http
            .patch<NotificationDto[]>(
                this.apiUrl(`notification/read/all`),
                {},
                {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${token}`,
                    }),
                },
            );
    }

    getMyNotifications(token = ""): Observable<PagedNotificationDto> {
        return this.http
            .get<PagedNotificationDto>(
                this.apiUrl(`notification/my?filter=read&filterValue=false`),
                {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${token}`,
                    }),
                },
            );
    }

    getTicketOverview(token = ""): Observable<TicketOverviewDto> {
        return this.http
            .get<TicketOverviewDto>(
                this.apiUrl(`ticket/analytics/overview`),
                {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${token}`,
                    }),
                },
            );
    }

    check(token = ""): Observable<UserDto> {
        return this.http
            .get<UserDto>(
                this.apiUrl(`user/check`),
                {
                    headers: new HttpHeaders({
                        'Authorization': `Bearer ${token}`,
                    }),
                },
            );
    }

    getServerTime(): Observable<ApiIndexDto> {
        return this.http
            .get<ApiIndexDto>(
                this.apiUrl(``),
                {},
            );
    }

    apiUrl(endpoint: string): string {
        const host: string = environment.apiEndpoint;
        const url = `${host}/${endpoint}`;
        return url;
    }
}