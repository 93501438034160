import { LogLevel } from "angular-auth-oidc-client";

export const environment = {
  production: true,
  apiEndpoint: 'https://support.efs-ag.com/v1',
  startEndpoint: 'https://start.efs-ag.com/v1',
  usermgmtEndpoint: 'https://api.efs-ag.services/v2',
  appUrl: 'https://support.efs-ag.com',

  openIdConfig: {
    authority: 'https://auth.efs-ag.services',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: 'https://support.efs-ag.com',
    clientId: 'efs.ticket',
    scope: 'openid email profile',
    responseType: 'code',
    silentRenew: false,
    useRefreshToken: false,
    logLevel: LogLevel.Error,
  },
};
