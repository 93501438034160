import { Component, NgZone, OnDestroy, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs';
import Swal from 'sweetalert2';
import { TicketAnswerAttachmentDto } from '../../dtos/ticket-answer-attachment.dto';
import { TicketAnswerDto } from '../../dtos/ticket-answer.dto';
import { TicketDto } from '../../dtos/ticket.dto';
import { asyncForEach, showError } from '../../helper/utils.helper';
import { TicketApiService } from '../../services/ticket-api.service';
import { saveAs } from 'file-saver';
import { PreloaderService } from '../../services/preloader.service';
import * as CustomBuildEditor from 'src/ckeditor5/build/ckeditor';
import { AudioRecordingService } from '../../services/audio-recording.service';
import { VideoRecordingService } from '../../services/video-recording.service';
import * as Sentry from "@sentry/angular";

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewTicketComponent implements OnInit, OnDestroy {

  isDark = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "oxide-dark" : "oxide");
  isDarkContent = (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "default");

  ticket_number: string = "";
  ticket: TicketDto | undefined;
  ticketAnswers: TicketAnswerDto[] = [];

  answerText: string = "";
  answerFiles: File[] = [];

  isLoading: boolean = false;

  userData: any;

  signature: string = "";

  noTranscriptionFound = false;
  isProcessing = false;
  isRecording = false;
  recordedTime = '';
  blobUrl!: string | null;
  recordingData: any;

  isVideoProcessing = false;
  isVideoRecording = false;
  recordedTimeVideo = '';
  blobUrlVideo!: string | null;
  recordingDataVideo: any;

  public editor = CustomBuildEditor;
  editorConfig: any = {
    placeholder: "Schreibe eine Antwort - Deine Signatur wird automatisch angehängt",
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'fontSize',
        'fontColor',
        '|',
        'outdent',
        'indent',
        'alignment',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'codeBlock',
        'code'
      ]
    },
    link: {
      decorators: {
        isExternal: {
          mode: 'automatic',
          callback: (url: String) => url.startsWith('http://') || url.startsWith('https://') || url.startsWith('www'),
          attributes: {
            target: '_blank'
          }
        }
      }
    },
    language: 'de',
  };

  constructor(
    protected route: ActivatedRoute,
    protected ticketApi: TicketApiService,
    protected domSanitizer: DomSanitizer,
    public oidcSecurityService: OidcSecurityService,
    private preloadService: PreloaderService,
    private audioRecordingService: AudioRecordingService,
    private videoRecordingService: VideoRecordingService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {

    this.audioRecordingService
      .recordingFailed()
      .subscribe((error) => {
        console.log(error);
        if (error && error.toString().includes('Permission denied')) {
          Swal.fire({
            icon: 'error',
            title: 'Aufnahme fehlgeschlagen',
            text: 'Der Website fehlt die Berechtigung das Mikrofon aufzunehmen. Bitte erlaube die Aufnahme in den Browsereinstellungen.',
            footer: 'Bei Fragen wenden Sie sich an den EFS-IT-Support unter <a href="mailto:support@efs-ag.com">support@efs-ag.com</a>.'
          });
        }

        this.isRecording = false;
        Sentry.captureException(error);
      });
    this.audioRecordingService
      .getRecordedTime()
      .subscribe(time => {
        this.recordedTime = time

        if (time === '05:00') {
          this.stopRecording();
        }
      });
    this.audioRecordingService.getRecordedBlob().subscribe(data => {
      this.recordingData = data;
      this.blobUrl = URL.createObjectURL(data.blob);
    });

    this.videoRecordingService
      .recordingFailed()
      .subscribe((error) => {
        console.log(error);
        if (error && error.toString().includes('Permission denied')) {
          Swal.fire({
            icon: 'error',
            title: 'Aufnahme fehlgeschlagen',
            text: 'Der Website fehlt die Berechtigung deinen Desktop aufzunehmen. Bitte erlaube die Aufnahme in den Browsereinstellungen.',
            footer: 'Bei Fragen wenden Sie sich an den EFS-IT-Support unter <a href="mailto:support@efs-ag.com">support@efs-ag.com</a>.'
          });
        }

        this.isVideoRecording = false;
        Sentry.captureException(error);
      });
    this.videoRecordingService
      .getRecordedTime()
      .subscribe(time => {
        this.recordedTimeVideo = time

        if (time === '05:00') {
          this.stopVideoRecording();
        }
      });
    this.videoRecordingService.getRecordedBlob().subscribe(data => {
      this.ngZone.run(() => {
        this.recordingDataVideo = data;
        this.isVideoRecording = false;
        console.log("Recording done, blob: ", this.blobUrlVideo);
        this.processVideoRecording();
      });
    });

    this.oidcSecurityService.getUserData().pipe(take(1)).subscribe((userData) => {
      this.userData = userData;
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.ticket_number = params['id'];

        this.preloadService.dataLoaded.subscribe({
          next: () => {
            this.loadData();
          },
          error: (err) => {
            this.isLoading = false;
            showError(err);
          },
        });
      }
    })
  }

  fileUploadEvent(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.answerFiles.push(event.target.files[i]);
    }
  }

  loadData() {
    this.isLoading = true;
    this.ticketApi.getMyTicket(this.ticket_number).subscribe({
      next: (data) => {
        this.ticket = data;
      },
      error: (error) => {
        showError(error);
        this.isLoading = false;
      },
    });

    this.ticketApi.getMyTicketAnswers(this.ticket_number).subscribe({
      next: (data) => {
        this.ticketAnswers = data;
      },
      error: (error) => {
        showError(error);
        this.isLoading = false;
      },
    });

    this.ticketApi.getMySignature().subscribe({
      next: (data) => {
        this.signature = "<p>&nbsp;</p>\n" + data.signature;
        this.answerText = this.signature;
        this.isLoading = false;
      },
      error: (err) => {
        showError(err);
        this.isLoading = false;
      }
    });
  }

  htmlContent(content: string) {
    return this.domSanitizer.sanitize(SecurityContext.NONE, content);
  }

  toggleFold(answer: TicketAnswerDto) {
    if (answer.folded === undefined) {
      answer.folded = false;
    } else {
      answer.folded = !answer.folded;
    }
  }

  async answerTicket() {

    this.isLoading = true;

    let uploadedData: TicketAnswerAttachmentDto[] = [];

    Swal.fire({
      title: 'Bitte warten ...',
      text: 'Der Vorgang kann einige Minuten in Anspruch nehmen.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (this.answerText.trim().length === 0 || this.answerText === this.signature) {
      this.isLoading = false;
      await Swal.fire({
        title: 'Bitte geben Sie eine Antwort ein!',
        icon: 'warning',
      })
      return;
    }

    if (this.answerText.includes('Anhang') && this.answerFiles.length === 0) {
      this.isLoading = false;
      await Swal.fire({
        text: 'Ihre Antwort enthält den Text "Anhang" aber Sie haben keine Datei angehängt. Möchten Sie trotzdem fortfahren?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Nein',
        cancelButtonText: 'Ja'
      }).then((result) => {
        if (result.isConfirmed) {
          throw new Error('Abort send');
        }
      })
    }

    if (this.answerFiles.length > 0) {
      await asyncForEach(this.answerFiles, async (file: File) => {
        const uploadedDataResponse = await this.ticketApi.uploadFiles(file).toPromise().catch((error) => {
          showError(error);
          this.isLoading = false;

          throw new Error('Upload failed!');
        });

        if (uploadedDataResponse) {
          uploadedData.push(uploadedDataResponse)
        }
      });
    }

    this.ticketApi.answerTicket(this.ticket!._id!, {
      text: this.answerText,
      attachments: uploadedData,
      type: 'customerAnswer',
      author_name: this.userData.name,
      author_email: this.userData.email,
      author_user_sso_id: this.userData.nickname,
      source: 'landingpage',
      template_data: [],
    }).subscribe({
      next: (data) => {
        this.isLoading = false;
        Swal.fire({
          html: `Ihre Anfrage wurde erfolgreich an uns übermittelt.<br>Wir versuchen Sie schnellstmöglich bei Ihrem Anliegen zu unterstützen.<br><br>Ihr EFS-IT-Support`,
          icon: 'success',
        }).then(() => {
          this.loadData();
          this.answerText = "";
          this.answerFiles = [];
        })
      },
      error: (error) => {
        showError(error);
        this.isLoading = false;
      },
    })

  }

  downloadAttachment(ticketAnswerId: string, attachment: TicketAnswerAttachmentDto) {
    this.ticketApi.downloadFile(ticketAnswerId, attachment.slug).subscribe({
      next: (data) => {

        const blob = new Blob([data], { type: attachment.mimetype });
        saveAs(blob, attachment.filename);

      },
      error: (error) => {
        showError(error);
      }
    });
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  processRecording() {
    this.isProcessing = true;
    const file = new File([this.recordingData.blob], `Sprachmitteilung_${new Date().toLocaleString().replace(', ', '_').replaceAll('.', '-').replaceAll(':', '-')}.mp3`, { type: 'audio/webm' });

    this.answerFiles.push(file);
    this.clearRecordedData();

    this.ticketApi.transcribeAudio(file, this.recordingData.duration).subscribe({
      next: (data) => {
        this.isProcessing = false;
        if (data.text === '') {
          this.noTranscriptionFound = true;
          setTimeout(() => {
            this.noTranscriptionFound = false;
          }, 10000);
        } else {
          if (this.answerText !== this.signature) {
            this.answerText = data.text + '<br>' + this.answerText;
          } else {
            this.answerText = data.text + this.signature;
          }
        }
      }, error: (error) => {
        this.isProcessing = false;
        showError(error);
      }
    });
  }

  startVideoRecording() {
    if (!this.isVideoRecording) {
      this.isVideoRecording = true;
      this.videoRecordingService.startRecording();
    }
  }

  abortVideoRecording() {
    if (this.isVideoRecording) {
      this.isVideoRecording = false;
      this.videoRecordingService.abortRecording();
    }
  }

  stopVideoRecording() {
    if (this.isVideoRecording) {
      this.videoRecordingService.stopRecording();
      this.isVideoRecording = false;
    }
  }

  clearVideoRecordedData() {
    this.blobUrlVideo = null;
    this.recordingDataVideo = null;
  }

  processVideoRecording() {
    console.log("Processing video recording", this.recordingDataVideo.blob);
    const file = new File([this.recordingDataVideo.blob], `Desktopaufnahme_${new Date().toLocaleString().replace(', ', '_').replaceAll('.', '-').replaceAll(':', '-')}.mp4`, { type: 'video/mp4' });
    this.answerFiles.push(file);
    this.clearVideoRecordedData();

    Swal.fire({
      title: 'Videoaufnahme',
      text: 'Die Aufnahme wurde erfolgreich abgeschlossen und ist dem Anliegen als Anhang hinzugefügt worden.',
      icon: 'success',
    })
  }

  removeAttachment(attachment: File) {
    this.answerFiles = this.answerFiles.filter((file) => file !== attachment);
  }

  getBlobUrl(file: File): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }
}
