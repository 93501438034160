<div class="page" *ngIf="authenticated && !userCheckSuccess && !isLoading">
  <div class="h-100">
    <div class="container d-flex flex-column">
      <div class="row align-items-center justify-content-center no-gutters min-vh-100">
        <div class="col-lg-5 col-md-8 py-8 py-md-11">
          <img src="https://www.efs-ag.de/assets/images/common/efs-logo.svg" class="mb-4 text-center image-center"  height="80px" alt="EFSsupport System">
          <h3 class="display-5 text-center mb-4">Ungültige Account-Daten!</h3>
          <p class="text-center mb-3 mt-3" style="font-size: 18px;">Ihr Benutzerkonto <strong *ngIf="userData">{{ userData.name }} ({{ userData.nickname }}) </strong>hat keine EFS E-Mail Adresse. Eine E-Mail Adresse ist notwenidg für die Nutzung dieses Systems.</p>
          <p class="text-center mb-3" style="font-size: 18px;">
            Bitte wenden Sie sich mit Ihrem Anliegen direkt per E-Mail an <a href="mailto:support@efs-ag.com">support&#64;efs-ag.com</a>!
          </p>

          <div class="text-center">
            <a class="btn btn-efs text-center" (click)="logout()">Ausloggen oder Account wechseln</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page" *ngIf="authenticated && userCheckSuccess">
  <aside class="navbar navbar-vertical navbar-expand-sm navbar-dark navbar-efs" *ngIf="showNavbar">
    <div class="container-fluid">
      <button class="navbar-toggler" id="navbarToggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#sidebar-menu" aria-controls="sidebar-menu" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark">
        <a href="#">
          <img src="https://files.efs-ag.services/EFS-Logo_weiss.png" width="110" height="32" alt="EFSsupport System"
            class="navbar-brand-image mt-2">
        </a>
      </h1>
      <div class="collapse navbar-collapse" id="sidebar-menu">
        <ul class="navbar-nav pt-lg-3">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/']" data-bs-toggle="collapse" data-bs-target="#sidebar-menu"
              routerLinkActive="active" [routerLinkActiveOptions]='{ exact: true }' *ngIf="!isAdmin">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-gauge" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                  <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                  <path d="M13.41 10.59l2.59 -2.59"></path>
                  <path d="M7 12a5 5 0 0 1 5 -5"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Dashboard
              </span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/admin/dashboard']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active" *ngIf="isAdmin">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-gauge" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                  <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                  <path d="M13.41 10.59l2.59 -2.59"></path>
                  <path d="M7 12a5 5 0 0 1 5 -5"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Dashboard
              </span>
            </a>
          </li>

          <li class="nav-item mb-3" *ngIf="isAdmin"></li>

          <li class="nav-item" *ngIf="!isAdmin">
            <a class="nav-link" [routerLink]="['/tickets']" data-bs-toggle="collapse" data-bs-target="#sidebar-menu"
              routerLinkActive="active" [routerLinkActiveOptions]='{ exact: true }'>
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24" height="24"
                  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Meine Anliegen
              </span>
            </a>
          </li>

          <!-- Tickets -->
          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/tickets/my']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active" [routerLinkActiveOptions]='{ exact: true }'>
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24" height="24"
                  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Meine Tickets
              </span>

              <span *ngIf="ticketOverviewData !== undefined"
                class="text-uppercase ms-auto text-small status status-secondary navbar-badge">{{
                ticketOverviewData.my_tickets_count }}</span>
            </a>
          </li>

          <li class="nav-item mb-1" *ngIf="isAdmin"></li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/tickets']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active" [routerLinkActiveOptions]='{ exact: true }'>
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list-details" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M13 5h8"></path>
                  <path d="M13 9h5"></path>
                  <path d="M13 15h8"></path>
                  <path d="M13 19h5"></path>
                  <path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                  <path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Anliegen
              </span>

              <span *ngIf="ticketOverviewData !== undefined"
                class="text-uppercase ms-auto text-small status status-secondary navbar-badge">{{
                ticketOverviewData.open_tickets_count }}</span>
            </a>
          </li>

          <li class="nav-item mb-1" *ngIf="isAdmin"></li>

          <!-- Bug-Reports -->
          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/bugreports']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active" [routerLinkActiveOptions]='{ exact: true }'>
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list-details" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M13 5h8"></path>
                  <path d="M13 9h5"></path>
                  <path d="M13 15h8"></path>
                  <path d="M13 19h5"></path>
                  <path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                  <path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Bug-Reports
              </span>

              <span *ngIf="ticketOverviewData !== undefined"
                class="text-uppercase ms-auto text-small status status-secondary navbar-badge">{{
                ticketOverviewData.open_bugreports_count }}</span>
            </a>
          </li>

          <li class="nav-item mb-1" *ngIf="isAdmin"></li>

          <!-- Feature-Wünsche -->
          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/featurerequests']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active" [routerLinkActiveOptions]='{ exact: true }'>
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list-details" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M13 5h8"></path>
                  <path d="M13 9h5"></path>
                  <path d="M13 15h8"></path>
                  <path d="M13 19h5"></path>
                  <path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                  <path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Feature-Wünsche
              </span>

              <span *ngIf="ticketOverviewData !== undefined"
                class="text-uppercase ms-auto text-small status status-secondary navbar-badge">{{
                ticketOverviewData.open_featurerequests_count }}</span>
            </a>
          </li>

          <li class="nav-item mb-3" *ngIf="isAdmin"></li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/incidents']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-triangle" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z">
                  </path>
                  <path d="M12 9v4"></path>
                  <path d="M12 17h.01"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Störungen
              </span>
            </a>
          </li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/predefined-answers']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-keyboard" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M2 6m0 2a2 2 0 0 1 2 -2h16a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-16a2 2 0 0 1 -2 -2z"></path>
                  <path d="M6 10l0 .01"></path>
                  <path d="M10 10l0 .01"></path>
                  <path d="M14 10l0 .01"></path>
                  <path d="M18 10l0 .01"></path>
                  <path d="M6 14l0 .01"></path>
                  <path d="M18 14l0 .01"></path>
                  <path d="M10 14l4 .01"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Vordefinierte Antworten
              </span>
            </a>
          </li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/labels']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-tag" width="24" height="24"
                  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="8.5" cy="8.5" r="1" fill="currentColor"></circle>
                  <path
                    d="M4 7v3.859c0 .537 .213 1.052 .593 1.432l8.116 8.116a2.025 2.025 0 0 0 2.864 0l4.834 -4.834a2.025 2.025 0 0 0 0 -2.864l-8.117 -8.116a2.025 2.025 0 0 0 -1.431 -.593h-3.859a3 3 0 0 0 -3 3z">
                  </path>
                </svg>
              </span>
              <span class="nav-link-title">
                Labels
              </span>
            </a>
          </li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/activities']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-timeline" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 16l6 -7l5 5l5 -6"></path>
                  <path d="M15 14m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                  <path d="M10 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                  <path d="M4 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                  <path d="M20 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Ereignisse
              </span>
            </a>
          </li>

          <li class="nav-item mb-3" *ngIf="isAdmin"></li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/priorities']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock-bolt" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M20.984 12.53a9 9 0 1 0 -7.552 8.355"></path>
                  <path d="M12 7v5l3 3"></path>
                  <path d="M19 16l-2 3h4l-2 3"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Prioritäten und SLAs
              </span>
            </a>
          </li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/templates']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clipboard-copy" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h3m9 -9v-5a2 2 0 0 0 -2 -2h-2"></path>
                  <path
                    d="M13 17v-1a1 1 0 0 1 1 -1h1m3 0h1a1 1 0 0 1 1 1v1m0 3v1a1 1 0 0 1 -1 1h-1m-3 0h-1a1 1 0 0 1 -1 -1v-1">
                  </path>
                  <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Ausfüllvorgaben
              </span>
            </a>
          </li>

          <li class="nav-item hidden" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/audit-logs']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-cog" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M12 18c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                  <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M19.001 15.5v1.5"></path>
                  <path d="M19.001 21v1.5"></path>
                  <path d="M22.032 17.25l-1.299 .75"></path>
                  <path d="M17.27 20l-1.3 .75"></path>
                  <path d="M15.97 17.25l1.3 .75"></path>
                  <path d="M20.733 20l1.3 .75"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Audit Logs
              </span>
            </a>
          </li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/users']" data-bs-toggle="collapse" data-bs-target="#sidebar-menu"
              routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Benutzer
              </span>
            </a>
          </li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" [routerLink]="['/admin/groups']" data-bs-toggle="collapse"
              data-bs-target="#sidebar-menu" routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Gruppen
              </span>
            </a>
          </li>

          <li class="nav-item mb-3" *ngIf="isAdmin">
          </li>

          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/my-account']" data-bs-toggle="collapse" data-bs-target="#sidebar-menu"
              routerLinkActive="active">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home-cog" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h1.6"></path>
                  <path d="M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h4.159"></path>
                  <path d="M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M18 14.5v1.5"></path>
                  <path d="M18 20v1.5"></path>
                  <path d="M21.032 16.25l-1.299 .75"></path>
                  <path d="M16.27 19l-1.3 .75"></path>
                  <path d="M14.97 16.25l1.3 .75"></path>
                  <path d="M19.733 19l1.3 .75"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Mein Konto
              </span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link link" (click)="logout()">
              <span
                class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout" width="24"
                  height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                  <path d="M7 12h14l-3 -3m0 6l3 -3"></path>
                </svg>
              </span>
              <span class="nav-link-title">
                Ausloggen
              </span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </aside>

  <header class="navbar navbar-expand-md d-print-none sticky-top" *ngIf="showSearchbar">
    <div class="container-fluid">

      <div class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">


        <div class="input-group input-group-flat" *ngIf="isAdmin">
          <button type="button" class="btn dropdown-toggle pe-2 ps-2" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-filter m-0" width="24"
              height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z">
              </path>
            </svg>
          </button>
          <div class="dropdown-menu">
            <a [classList]="searchMode === 'all' ? 'dropdown-item active' : 'dropdown-item'"
              (click)="searchMode = 'all'">Volltext</a>
            <a [classList]="searchMode === 'subject' ? 'dropdown-item active' : 'dropdown-item'"
              (click)="searchMode = 'subject'">Betreff</a>
            <a [classList]="searchMode === 'author' ? 'dropdown-item active' : 'dropdown-item'"
              (click)="searchMode = 'author'">Autor</a>
            <a [classList]="searchMode === 'ticketNumber' ? 'dropdown-item active' : 'dropdown-item'"
              (click)="searchMode = 'ticketNumber'">Anliegen Nr.</a>

            <div class="dropdown-divider"></div>

            <a [classList]="searchType === 'none' ? 'dropdown-item active' : 'dropdown-item'"
              (click)="searchType = 'none'">Alle Anfragen</a>
            <a [classList]="searchType === 'bug_report' ? 'dropdown-item active' : 'dropdown-item'"
              (click)="searchType = 'bug_report'">Nur Bug-Reports</a>
            <a [classList]="searchType === 'support_request' ? 'dropdown-item active' : 'dropdown-item'"
              (click)="searchType = 'support_request'">Nur Support Anfragen</a>
            <a [classList]="searchType === 'feature_request' ? 'dropdown-item active' : 'dropdown-item'"
              (click)="searchType = 'feature_request'">Nur Feature-Wünsche</a>
          </div>

          

          <input (keydown)="startSearch($event)" [(ngModel)]="search" type="text" class="form-control "
            placeholder="Suchen (↵ Enter)" type="search" name="search" aria-label="Anliegen durchsuchen">
        </div>
      </div>

      <div class="navbar-nav flex-row order-md-last me-2" style="margin-left: auto;">
        <div class="d-flex">

          <div class="nav-item dropdown d-flex me-3">
            <a href="#" class="nav-link px-0" data-bs-toggle="dropdown" tabindex="-1" aria-label="Show notifications"
              *ngIf="isAdmin">
              <!-- Download SVG icon from http://tabler-icons.io/i/bell -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon ticket-icon">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
              </svg>
              <span *ngIf="notifications.length > 0" class="badge bg-red"></span>
            </a>

            <div class="dropdown-menu notification dropdown-menu-arrow dropdown-menu-end dropdown-menu-card"
              *ngIf="isAdmin">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">
                    Meine Benachrichtigungen
                  </h3>

                  <div class="card-actions btn-actions">
                    <a [ngbTooltip]="'Alle ungelesenen Benachrichtigungen als gelesen markieren'"
                      (click)="markAllNotificationAsRead()" class="link me-2"><svg xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-trash" width="76" height="76" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 7l16 0" />
                        <path d="M10 11l0 6" />
                        <path d="M14 11l0 6" />
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg></a>
                  </div>
                </div>
                <div class="list-group list-group-flush list-group-hoverable">

                  <div class="list-group-item" *ngIf="notifications.length === 0">
                    <div class="row align-items-center">
                      <div class="col text-truncate">
                        <a href="#" class="text-body d-block text-center"></a>
                        <div class="d-block text-secondary text-truncate text-center">Keine ungelesenen
                          Benachrichtigungen!</div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="notifications.length > 0">
                    <div class="list-group-item p-3" *ngFor="let notification of notifications">
                      <div class="row align-items-center">
                        <div class="col text-truncate link" (click)="openNotification(notification)">
                          <div class="d-block text-truncate" [innerHTML]="notification.text"></div>
                        </div>
                        <div class="col-auto" *ngIf="!notification.read">
                          <a [ngbTooltip]="'Als gelesen markieren'" (click)="markNotificationAsRead(notification)"
                            class="link ms-1"><svg xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-trash" width="76" height="76" viewBox="0 0 24 24"
                              stroke-width="1.5" stroke="#9e9e9e" fill="none" stroke-linecap="round"
                              stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M4 7l16 0" />
                              <path d="M10 11l0 6" />
                              <path d="M14 11l0 6" />
                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                            </svg></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-item dropdown d-none d-sm-flex">
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
            <div class="ps-2">
              <div>{{ userData.name }}</div>
              <div class="mt-1 small text-secondary">{{ userData.nickname }}</div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <a (click)="logout()" class="dropdown-item">Ausloggen</a>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="page-wrapper">
    <router-outlet></router-outlet>
    <app-footer *ngIf="showNavbar"></app-footer>
  </div>
</div>